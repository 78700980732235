// 
// 
// user.scss
//
// Place your own custom CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.

[class*="display"] {
  font-weight: 300;
}

.form-search {
  display: none;
}

iframe {
  width: 100%;
  height: 600px;
}